import * as React from "react";

import { cn } from "../../lib/utils";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	icon?: React.ReactNode;
	errorMessage?: string;
	containerClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, icon, type, errorMessage, containerClassName, ...props }, ref) => {
		return (
			<div
				className={cn("relative flex items-center", {
					"rounded-md border": icon,
				}, containerClassName)}
			>
				{icon && <div className="pl-2">{icon}</div>}
				<input
					type={type}
					className={cn(
						"flex h-10 w-full border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2",
						"disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300",
						{ "rounded-md border": !icon },
						className,
					)}
					ref={ref}
					{...props}
				/>
				{errorMessage && ( // Render error message if present
					<div className="text-xs text-red-500 mt-1">{errorMessage}</div>
				)}
			</div>
		);
	},
);
Input.displayName = "Input";

export { Input };
